import { OnMessageCallback } from 'mqtt';
import { store } from '../../app/store';
import { aeroActions } from './aeroSlice';

export const adsbMqttParser: OnMessageCallback = (topic: any, payload: any, packet: any): void => {
    const msg = JSON.parse(payload.toString());
    const [, , , source] = topic.split("/");

    msg.features.forEach((f: any) => {
        store.dispatch(aeroActions.add_adsb_feature({ feature: f, source , config: store.getState().mission.activeConfig}));
    });
};