import { call, put, all, select, takeLatest, takeEvery } from "redux-saga/effects";
import { customActions, CustomProductType } from "./customSlice";
import { missionActions } from "../mission/missionSlice";
import { replayActions } from "../replay/replaySlice";
import { RootState } from "../../app/store";
import { CUSTOM_API } from "./customApi";
import { API_CONFIG } from '../../config/api';

type WhatYouYield = any;
type WhatYouReturn = any;
type WhatYouAccept = any;

function* getCustoms(action: { payload: any, type: string }): Generator<WhatYouYield, WhatYouReturn, WhatYouAccept> {
    try {
        yield put(customActions.get_customs({}));
        const token: string = yield select((state: RootState) => state.auth.token);
        const missionId: string = yield select((state: RootState) => state.mission.active);
        const res: any = yield call(CUSTOM_API.getCustoms, token, missionId);
        const customsLS = JSON.parse(localStorage.getItem('_customs') || "{}");
        const list = res.data.reduce((acc: { [key: string]: CustomProductType }, value: any, index: number) => ({
            ...acc, [value.id]: {
                ...value,
                url: `${API_CONFIG.HOSTNAME}/custom/hash/${value.hash}`,
                visible: customsLS[value.id]?.visible !== undefined ? customsLS[value.id].visible : true,
                zIndex: customsLS[value.id]?.zIndex || index + 1,
                opacity: customsLS[value.id]?.opactiy || 100
            }
        }), {});
        yield put(customActions.get_customs_success({ list }));
    } catch (error: any) {
        yield put(customActions.get_customs_failure({ status: error.response?.status, text: error.response?.statusText, data: error.response?.data }));
    };
};

function* watchSetActiveMission() {
    yield takeLatest([missionActions.set_active_mission, replayActions.stop], getCustoms);
};

function* addOrEditCustom(action: { payload: any, type: string }): Generator<WhatYouYield, WhatYouReturn, WhatYouAccept> {
    try {
        const token: string = yield select((state: RootState) => state.auth.token);
        const missionId: string = yield select((state: RootState) => state.mission.active);
        if (action.payload.id) {
            yield call(CUSTOM_API.patchCustom, token, missionId, action.payload.id, action.payload.minLon, action.payload.maxLon, action.payload.minLat, action.payload.maxLat, action.payload.sourceProjection);
        } else {
            yield call(CUSTOM_API.postCustom, token, missionId, action.payload.file, action.payload.fileType, action.payload.minLon, action.payload.maxLon, action.payload.minLat, action.payload.maxLat, action.payload.sourceProjection);
        }
        yield put(customActions.add_success({ }));
    } catch (error: any) {
        yield put(customActions.add_failure({ status: error.response?.status, text: error.response?.statusText, data: error.response?.data }));
    };
};

function* watchAdd() {
    yield takeEvery(customActions.add, addOrEditCustom);
};

function* deleteCustom(action: { payload: any, type: string }): Generator<WhatYouYield, WhatYouReturn, WhatYouAccept> {
    try {
        const token: string = yield select((state: RootState) => state.auth.token);
        const missionId: string = yield select((state: RootState) => state.mission.active);
        yield call(CUSTOM_API.deleteCustom, token, missionId, action.payload.id);
        yield put(customActions.delete_success({ id: action.payload.id }));
    } catch (error: any) {
        yield put(customActions.delete_failure({ status: error.response?.status, text: error.response?.statusText, data: error.response?.data }));
    };
};

function* watchDelete() {
    yield takeEvery(customActions.delete, deleteCustom);
};

function* setLocalStorage(action: { payload: any, type: string }): Generator<WhatYouYield, WhatYouReturn, WhatYouAccept> {
    const customs = yield select((state: RootState) => state.nav.custom.files);
    let ls = {};
    Object.entries(customs).forEach(([customId, custom]: [string, any]) => {
        ls = {
            ...ls,
            [customId]: {
                visible: custom.visible,
                zIndex: custom.zIndex,
                opacity: custom.opacity
            }
        };
    });

    localStorage.setItem('_customs', JSON.stringify(ls));
};

function* watchCustomChange() {
    yield takeLatest([customActions.set_visibility, customActions.set_opacity, customActions.move_up, customActions.move_down, customActions.append, customActions.remove], setLocalStorage);
};

export default function* watchCustom() {
    yield all([
        watchSetActiveMission(),
        watchAdd(),
        watchDelete(),
        watchCustomChange(),
    ]);
};