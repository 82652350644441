import { all, select, takeLatest, call, put } from "redux-saga/effects";
import { mapActions } from './mapSlice';
import { authActions } from "../auth/authSlice";
import { RootState } from '../../app/store';
import axios from 'axios';

type WhatYouYield = any;
type WhatYouReturn = any;
type WhatYouAccept = any;

function* setLocalStorage(action: { payload: any, type: string }): Generator<WhatYouYield, WhatYouReturn, WhatYouAccept> {
    const map = yield select((state: RootState) => ({
        background: state.map.background,
        projection: state.map.projection,
        graticule: state.map.graticule,
        view: state.map.view,
        localOrigin: state.map.localOrigin,
        enable_gm_measures: state.map.enable_gm_measures
    }));
    localStorage.setItem("_map", JSON.stringify(map));
};

function* watchActionsForLS() {
    yield takeLatest([
        mapActions.set_background,
        mapActions.set_projection,
        mapActions.set_view,
        mapActions.set_graticule,
        mapActions.set_origin,
        mapActions.set_enable_gm_measures,
        mapActions.set_show_origin_layer
    ], setLocalStorage);
};

function* getTileserverIndex(action: { payload: any, type: string }): Generator<WhatYouYield, WhatYouReturn, WhatYouAccept> {

    try {
        const res: any = yield call(axios.get, 'tileserver/index.json');
        const available = res?.data.filter((d: any) => d.format === "png").map((d: any) => {
            return ({
                ...d,
                dark: d.tiles[0]?.includes('dark')
            })
        });

        if (available.length > 0) {
            yield put(mapActions.set_available_backgrounds({ backgrounds: available }));
        };

    } catch (error: any) { };

};

function* watchGetUserInfo() {
    yield takeLatest(authActions.get_user_info_success, getTileserverIndex);
};

export default function* watchMap() {
    yield all([
        watchGetUserInfo(),
        watchActionsForLS(),
    ]);
};