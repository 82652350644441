const COLORS_FULLSET = [
    ["AliceBlue", "#f0f8ff"],
    ["AntiqueWhite", "#faebd7"],
    ["Aqua", "#00ffff"],
    ["Aquamarine", "#7fffd4"],
    ["Azure", "#f0ffff"],
    ["Beige", "#f5f5dc"],
    ["Bisque", "#ffe4c4"],
    ["BlanchedAlmond", "#ffebcd"],
    ["Brown", "#a52a2a"],
    ["BurlyWood", "#deb887"],
    ["CadetBlue", "#5f9ea0"],
    ["Chartreuse", "#7fff00"],
    ["Chocolate", "#d2691e"],
    ["Coral", "#ff7f50"],
    ["CornflowerBlue", "#6495ed"],
    ["Cornsilk", "#fff8dc"],
    ["Crimson", "#dc143c"],
    ["Cyan", "#00ffff"],
    ["DarkCyan", "#008b8b"],
    ["DarkGoldenRod", "#b8860b"],
    ["DarkKhaki", "#bdb76b"],
    ["DarkOliveGreen", "#556b2f"],
    ["Darkorange", "#ff8c00"],
    ["DarkOrchid", "#9932cc"],
    ["DarkSalmon", "#e9967a"],
    ["DarkSeaGreen", "#8fbc8f"],
    ["DarkTurquoise", "#00ced1"],
    ["DeepPink", "#ff1493"],
    ["DeepSkyBlue", "#00bfff"],
    ["DimGray", "#696969"],
    ["DodgerBlue", "#1e90ff"],
    ["FireBrick", "#b22222"],
    ["FloralWhite", "#fffaf0"],
    ["ForestGreen", "#228b22"],
    ["Fuchsia", "#ff00ff"],
    ["Gainsboro", "#dcdcdc"],
    ["GhostWhite", "#f8f8ff"],
    ["Gold", "#ffd700"],
    ["GoldenRod", "#daa520"],
    ["Grey", "#808080"],
    ["Green", "#008000"],
    ["GreenYellow", "#adff2f"],
    ["HoneyDew", "#f0fff0"],
    ["HotPink", "#ff69b4"],
    ["IndianRed", "#cd5c5c"],
    ["Ivory", "#fffff0"],
    ["Khaki", "#f0e68c"],
    ["Lavender", "#e6e6fa"],
    ["LavenderBlush", "#fff0f5"],
    ["LawnGreen", "#7cfc00"],
    ["LemonChiffon", "#fffacd"],
    ["LightBlue", "#add8e6"],
    ["LightCyan", "#e0ffff"],
    ["LightGoldenRodYellow", "#fafad2"],
    ["LightGrey", "#d3d3d3"],
    ["LightGreen", "#90ee90"],
    ["LightPink", "#ffb6c1"],
    ["LightSalmon", "#ffa07a"],
    ["LightSeaGreen", "#20b2aa"],
    ["LightSkyBlue", "#87cefa"],
    ["LightSlateGrey", "#778899"],
    ["LightSteelBlue", "#b0c4de"],
    ["LightYellow", "#ffffe0"],
    ["Lime", "#00ff00"],
    ["LimeGreen", "#32cd32"],
    ["Linen", "#faf0e6"],
    ["Magenta", "#ff00ff"],
    ["MediumAquaMarine", "#66cdaa"],
    ["MediumOrchid", "#ba55d3"],
    ["MediumPurple", "#9370db"],
    ["MediumSeaGreen", "#3cb371"],
    ["MediumSlateBlue", "#7b68ee"],
    ["MediumSpringGreen", "#00fa9a"],
    ["MediumTurquoise", "#48d1cc"],
    ["MediumVioletRed", "#c71585"],
    ["MintCream", "#f5fffa"],
    ["MistyRose", "#ffe4e1"],
    ["Moccasin", "#ffe4b5"],
    ["NavajoWhite", "#ffdead"],
    ["OldLace", "#fdf5e6"],
    ["Olive", "#808000"],
    ["OliveDrab", "#6b8e23"],
    ["Orange", "#ffa500"],
    ["OrangeRed", "#ff4500"],
    ["Orchid", "#da70d6"],
    ["PaleGoldenRod", "#eee8aa"],
    ["PaleGreen", "#98fb98"],
    ["PaleTurquoise", "#afeeee"],
    ["PaleVioletRed", "#db7093"],
    ["PapayaWhip", "#ffefd5"],
    ["PeachPuff", "#ffdab9"],
    ["Peru", "#cd853f"],
    ["Pink", "#ffc0cb"],
    ["Plum", "#dda0dd"],
    ["PowderBlue", "#b0e0e6"],
    ["Red", "#ff0000"],
    ["RosyBrown", "#bc8f8f"],
    ["RoyalBlue", "#4169e1"],
    ["SaddleBrown", "#8b4513"],
    ["Salmon", "#fa8072"],
    ["SandyBrown", "#f4a460"],
    ["SeaGreen", "#2e8b57"],
    ["SeaShell", "#fff5ee"],
    ["Sienna", "#a0522d"],
    ["Silver", "#c0c0c0"],
    ["SkyBlue", "#87ceeb"],
    ["SlateBlue", "#6a5acd"],
    ["SlateGrey", "#708090"],
    ["Snow", "#fffafa"],
    ["SpringGreen", "#00ff7f"],
    ["SteelBlue", "#4682b4"],
    ["Tan", "#d2b48c"],
    ["Teal", "#008080"],
    ["Thistle", "#d8bfd8"],
    ["Tomato", "#ff6347"],
    ["Turquoise", "#40e0d0"],
    ["Violet", "#ee82ee"],
    ["Wheat", "#f5deb3"],
    ["WhiteSmoke", "#f5f5f5"],
    ["Yellow", "#ffff00"],
    ["YellowGreen", "#9acd32"]
];

const COLORS_RESTRICTEDSET = [
    ["Chartreuse", "#7fff00"],
    ["Cyan", "#00ffff"],
    ["OrangeRed", "#ff4500"],
    ["MediumSeaGreen", "#3cb371"],
    ["Magenta", "#ff00ff"],
    ["LimeGreen", "#32cd32"],
    ["Yellow", "#ffff00"],
    ["Sienna", "#a0522d"],
    ["Violet", "#ee82ee"],
    ["RoyalBlue", "#4169e1"]
];

export default class StringUtils {

    //https://gist.github.com/0x263b/2bdd90886c2036a1ad5bcf06d6e6fb37

    static stringToColor(string: string, restricted: boolean = false): string {

        const colors = restricted ? COLORS_RESTRICTEDSET : COLORS_FULLSET;

        let hash = 0;
        let i;

        if (string.length === 0) return hash.toString();

        /* eslint-disable no-bitwise */
        for (i = 0; i < string.length; i += 1) {
            hash = string.charCodeAt(i) + ((hash << 5) - hash);
            hash = hash & hash;
        }

        hash = ((hash % colors.length) + colors.length) % colors.length;
        return colors[hash][1];
    };


    static _9digits = (s: string) => {
        if (s.length === 1) s = s + "        ";
        else if (s.length === 2) s = s + "       ";
        else if (s.length === 3) s = s + "      ";
        else if (s.length === 4) s = s + "     ";
        else if (s.length === 5) s = s + "    ";
        else if (s.length === 6) s = s + "   ";
        else if (s.length === 7) s = s + "  ";
        else if (s.length === 8) s = s + " ";
        return s;
    };

    static capitalizeFirstLetter(string: string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    };

    static reverseString(str: string) {
        var newString = "";
        for (var i = str.length - 1; i >= 0; i--) {
            newString += str[i];
        }
        return newString;
    };
};