import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { AIRPORT_TYPES, NAVAID_TYPES } from '../../config/aero';
import { missionActions } from '../mission/missionSlice';

export interface AirportType {
    icao: string;
    name: string;
    elevation: number;
};

export interface MetarType {
    icao: string;
    loading: boolean;
    error: string | null;
    data: any;
};

export interface NavaidType { };

export interface AdsbAircraft {
    id: string;
    geometry: any;
    properties: any;
    hud: any;
};

export interface AeroState {
    airports: {
        visible: boolean;
        loading: boolean;
        loaded: boolean;
        error: string | null;
        list: AirportType[];
        metars: MetarType[];
        displayedTypes: string[];
    };
    navaids: {
        visible: boolean;
        loading: boolean;
        loaded: boolean;
        error: string | null;
        list: NavaidType[];
        displayedTypes: string[];
    };
    adsb: {
        visible: boolean;
        loading: boolean;
        loaded: boolean;
        error: string | null;
        source: string | null;
        list: AdsbAircraft[];
        filters: any[];
    };
};

const aeroLS = JSON.parse(localStorage.getItem('_aero') || "{}");

const initialState: AeroState = {
    airports: {
        visible: false,
        loading: false,
        loaded: false,
        error: null,
        list: [],
        metars: [],
        displayedTypes: AIRPORT_TYPES.filter((t: string) => !t.includes('SMALL_')),
    },
    navaids: {
        visible: false,
        loading: false,
        loaded: false,
        error: null,
        list: [],
        displayedTypes: NAVAID_TYPES,
    },
    adsb: {
        visible: false,
        loading: false,
        loaded: false,
        error: null,
        source: null,
        list: [],
        filters: aeroLS.adsb?.filters || []
    }
};

const aeroSlice = createSlice({
    name: 'aero',
    initialState,
    reducers: {
        get_airports: ((state, action) => {
            state.airports.loading = true;
            state.airports.loaded = false;
            state.airports.error = null;
        }),
        get_airports_success: ((state, action) => {
            state.airports.loading = false;
            state.airports.loaded = true;
            state.airports.list = action.payload.airports;
        }),
        get_airports_failure: ((state, action) => {
            state.airports.loading = false;
            state.airports.loaded = true;
            state.airports.error = `Error ${action.payload.status}: ${action.payload.text}`;
        }),
        set_airports_visibility: ((state, action) => {
            state.airports.visible = action.payload.visible;
        }),
        add_airport_type: ((state, action) => {
            const index = state.airports.displayedTypes.findIndex((t: string) => t === action.payload.type);
            if (index === -1) state.airports.displayedTypes.push(action.payload.type);
        }),
        remove_airport_type: ((state, action) => {
            const index = state.airports.displayedTypes.findIndex((t: string) => t === action.payload.type);
            if (index !== -1) state.airports.displayedTypes = [...state.airports.displayedTypes.slice(0, index), ...state.airports.displayedTypes.slice(index + 1)];
        }),
        airport_click: ((state, action) => { }),
        airport_dblClick: ((state, action) => { }),
        get_metar: ((state, action) => {
            const index = state.airports.metars.findIndex((m: MetarType) => m.icao === action.payload.icao);
            if (index !== -1) state.airports.metars.splice(index, 1);
            state.airports.metars.push({
                icao: action.payload.icao,
                error: null,
                loading: true,
                data: null
            });
        }),
        get_metar_success: ((state, action) => {
            const index = state.airports.metars.findIndex((m: MetarType) => m.icao === action.payload.icao);
            if (index !== -1) {
                state.airports.metars[index].data = action.payload.metar;
                state.airports.metars[index].error = null;
                state.airports.metars[index].loading = false;
            };
        }),
        get_metar_failure: ((state, action) => {
            const index = state.airports.metars.findIndex((m: MetarType) => m.icao === action.payload.icao);
            if (index !== -1) {
                state.airports.metars[index].data = null;
                state.airports.metars[index].error = `Error ${action.payload.status}: ${action.payload.text}`;
                state.airports.metars[index].loading = false;
            };
        }),
        get_navaids: ((state, action) => {
            state.navaids.loading = true;
            state.navaids.loaded = false;
            state.navaids.error = null;
        }),
        get_navaids_success: ((state, action) => {
            state.navaids.loading = false;
            state.navaids.loaded = true;
            state.navaids.list = action.payload.navaids;
        }),
        get_navaids_failure: ((state, action) => {
            state.navaids.loading = false;
            state.navaids.loaded = true;
            state.navaids.error = `Error ${action.payload.status}: ${action.payload.text}`;
        }),
        set_navaids_visibility: ((state, action) => {
            state.navaids.visible = action.payload.visible;
        }),
        add_navaid_type: ((state, action) => {
            const index = state.navaids.displayedTypes.findIndex((t: string) => t === action.payload.type);
            if (index === -1) state.navaids.displayedTypes.push(action.payload.type);
        }),
        remove_navaid_type: ((state, action) => {
            const index = state.navaids.displayedTypes.findIndex((t: string) => t === action.payload.type);
            if (index !== -1) state.navaids.displayedTypes = [...state.navaids.displayedTypes.slice(0, index), ...state.navaids.displayedTypes.slice(index + 1)];
        }),
        add_adsb_feature: ((state, action) => {

            const index = state.adsb.list.findIndex((f: any) => f.id === action.payload.feature.id);

            if (index === -1) {
                state.adsb.list.push({
                    ...action.payload.feature,
                    hud: { // TODO : handle ID specific configurations
                        shown: false,
                        values: action.payload.config?.["aero"]?.["adsb"]?.["default"]?.["hud"]?.["values"]
                    }
                });
            } else {
                state.adsb.list[index] = {
                    ...state.adsb.list[index],
                    geometry: action.payload.feature.geometry,
                    properties: action.payload.feature.properties
                }
            }

            state.adsb.source = action.payload.source;
        }),
        add_adsb_computed: ((state, action) => {
            const index = action.payload.index
            state.adsb.list[index] = {
                ...state.adsb.list[index],
                properties: {
                    ...state.adsb.list[index].properties,
                    ...action.payload.computed
                }
            }
        }),
        set_adsb_visibility: ((state, action) => {
            state.adsb.visible = action.payload.visible;
        }),
        adsb_aircraft_click: ((state, action) => { }),
        adsb_aircraft_dblClick: ((state, action) => { }),
        adsb_toggle_hud: ((state, action) => {
            const index = state.adsb.list.findIndex((f: any) => f.id === action.payload.id);
            if (index !== -1) {
                state.adsb.list[index] = {
                    ...state.adsb.list[index],
                    hud: {
                        ...state.adsb.list[index].hud,
                        shown: !state.adsb.list[index].hud.shown
                    }
                }
            }
        }),
        add_adsb_filter: ((state, action) => {
            state.adsb.filters.push(action.payload.filter);
        }),
        moveup_adsb_filter: ((state, action) => {
            state.adsb.filters.splice(
                action.payload.index - 1,
                2,
                state.adsb.filters[action.payload.index],
                state.adsb.filters[action.payload.index - 1]
            );

        }),
        movedown_adsb_filter: ((state, action) => {
            state.adsb.filters.splice(
                action.payload.index,
                2,
                state.adsb.filters[action.payload.index + 1],
                state.adsb.filters[action.payload.index]
            );
        }),
        delete_adsb_filter: ((state, action) => {
            state.adsb.filters.splice(action.payload.index, 1);
        }),
        modify_adsb_filter: ((state, action) => {
            state.adsb.filters[action.payload.index] = action.payload.filter;
        }),
    },
    extraReducers: (builder) => {
        builder.addCase(missionActions.set_active_mission, state => {
            Object.assign(state, initialState);
        });
    }
});

export const selectAero = (state: RootState) => state.nav.aero;

export const aeroActions = aeroSlice.actions;

export default aeroSlice.reducer;