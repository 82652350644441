import { createSlice } from '@reduxjs/toolkit';
import { RootState } from '../../app/store';
import { missionActions } from '../mission/missionSlice';
import { replayActions } from '../replay/replaySlice';
import { COLOR_TRACKING_FUNCTIONS, COLOR_TRACKING_BY_ALT } from '../../config/tracking';

export interface TrackerType {
    status: string,
    name: string,
    kind: string,
    virtual: boolean,
    shown: boolean,
    hud: {
        shown: boolean,
        values: any,
    },
    eta: {
        drawing: boolean,
        targets: any[],
    },
    circles: boolean,
    length: number,
    source: string | null,
    colorTrack: string;
}

export interface TrackingState {
    [key: string]: TrackerType
};

const initialState: TrackingState = {};

const getValuesFromLocalStorage = (plid: string) => {
    const ls = localStorage.getItem("_tracking");
    const defaults = {
        circles: true,
        length: 1800,
        source: null,
        colorTrack: Object.entries(COLOR_TRACKING_FUNCTIONS).find(([key, el]) => el.default===true)?.[0] || COLOR_TRACKING_BY_ALT
    };

    if (!ls || !plid) return defaults;

    const json = JSON.parse(ls);

    if (!(plid in json)) return defaults;

    return {
        circles: typeof json[plid].circles !== 'undefined' ? json[plid].circles : defaults.circles,
        length: typeof json[plid].length !== 'undefined' ? json[plid].length : defaults.length,
        source: typeof json[plid].source !== 'undefined' ? json[plid].source : defaults.source,
        colorTrack: typeof json[plid].colorTrack !== 'undefined' ? json[plid].colorTrack : defaults.colorTrack,
    };
};

const trackingSlice = createSlice({
    name: 'tracking',
    initialState,
    reducers: {
        add_tracker: ((state, action) => {
            state[action.payload.plid] = {
                status: 'unknown',
                name: action.payload.name,
                kind: action.payload.kind,
                virtual: action.payload.virtual,
                shown: true,
                hud: {
                    shown: true,
                    values: action.payload.config?.hud?.values
                },
                eta: {
                    drawing: false,
                    targets: []
                },
                ...getValuesFromLocalStorage(action.payload.plid)
            }
        }),
        show: ((state, action) => {
            state[action.payload.plid].shown = true;
        }),
        hide: ((state, action) => {
            state[action.payload.plid].shown = false;
        }),
        show_circles: ((state, action) => {
            state[action.payload.plid].circles = true;
        }),
        hide_cirlces: ((state, action) => {
            state[action.payload.plid].circles = false;
        }),
        show_hud: ((state, action) => {
            state[action.payload.plid].hud.shown = true;
        }),
        hide_hud: ((state, action) => {
            state[action.payload.plid].hud.shown = false;
        }),
        set_tracker_length: ((state, action) => {
            state[action.payload.plid].length = action.payload.length;
        }),
        set_tracker_source: ((state, action) => {
            if (action.payload.plid in state) state[action.payload.plid].source = action.payload.source;
        }),
        set_status: ((state, action) => {
            if (action.payload.plid in state) state[action.payload.plid].status = action.payload.status;
        }),
        set_tracker_color_track: ((state, action) => {
            state[action.payload.plid].colorTrack = action.payload.colorTrack;
        }),
    },
    extraReducers: (builder) => {
        builder.addCase(missionActions.set_active_mission, state => {
            for (let key in state) delete state[key];
            Object.assign(state, initialState);
        });
        builder.addCase(replayActions.start, state => {
            for (let key in state) delete state[key];
            Object.assign(state, initialState);
        });
        builder.addCase(replayActions.stop, state => {
            for (let key in state) delete state[key];
            Object.assign(state, initialState);
        });
    }
});

export const selectTracking = (state: RootState) => state.nav.tracking;

export const trackingActions = trackingSlice.actions;

export default trackingSlice.reducer;
