import { put, all, select, takeLatest, takeEvery } from "redux-saga/effects";
import merge from "deepmerge";
import { missionActions, VehicleType } from '../mission/missionSlice';
import { RootState } from "../../app/store";
import { trackingActions, TrackingState } from "./trackingSlice";
import { telemetryActions } from "../telemetry/telemetrySlice";
import { replayActions } from "../replay/replaySlice";

type WhatYouYield = any;
type WhatYouReturn = any;
type WhatYouAccept = any;

function* getFleet(action: { payload: any, type: string }): Generator<WhatYouYield, WhatYouReturn, WhatYouAccept> {
    const replay = yield select((state: RootState) => state.replay.isActive);
    let fleet: VehicleType[];

    if (replay) {
        fleet = action.payload.fleet;
    } else {
        fleet = yield select((state: RootState) => {
            if (state.mission.active) return (state.mission.allowed[state.mission.active].fleet);
            return [];
        });
    };
    
    const config: any = yield select((state: RootState) => {
        if (state.mission.active) return (state.mission.allowed[state.mission.active].config?.tracking);
        return undefined;
    });

    yield all(fleet.map((v: any) => put(trackingActions.add_tracker({
        plid: v.id,
        name: v.name,
        kind: v.kind,
        virtual: v.virtual,
        config: merge(config?.default || {}, config?.[v.id] || {}),
        replay,
        from: (replay ? action.payload.from : null),
        to: (replay ? action.payload.to : null),
        track: v.track,
        labels: v.labels
    }))));
};

function* watchSetActiveMission() {
    yield takeLatest([missionActions.set_active_mission, replayActions.stop, replayActions.start], getFleet);
};

function* addTracker(action: { payload: any, type: string }): Generator<WhatYouYield, WhatYouReturn, WhatYouAccept> {
    if (action.payload.track && action.payload.labels?.[0]) {
        yield put(telemetryActions.get_history_success({ 
            plid: action.payload.plid,
            label:  action.payload.labels?.[0],
            is_source: true,
            data: action.payload.track
        }));
        yield put(trackingActions.set_tracker_source({ plid: action.payload.plid, source: action.payload.labels?.[0] }));
    } else {
        yield put(telemetryActions.get_labels({plid: action.payload.plid, replay: action.payload.replay, from: action.payload.from, to: action.payload.to})); 
    }
};

function* watchAddTracker() {
    yield takeEvery(trackingActions.add_tracker, addTracker);
};

function* setLocalStorage(action: { payload: any, type: string }): Generator<WhatYouYield, WhatYouReturn, WhatYouAccept> {
    const tracking: TrackingState = yield select((state: RootState) => state.nav.tracking);
    localStorage.setItem('_tracking', JSON.stringify(tracking));
};

function* watchActionsForLS() {
    yield takeEvery([
        trackingActions.show_circles,
        trackingActions.hide_cirlces,
        trackingActions.show_hud,
        trackingActions.hide_hud,
        trackingActions.set_tracker_length,
        trackingActions.set_tracker_source,
        trackingActions.set_tracker_color_track
    ], setLocalStorage);
};

export default function* watchTracking() {
    yield all([
        watchSetActiveMission(),
        watchAddTracker(),
        watchActionsForLS(),
    ]);
};